// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pl-2 {
  padding-left: 0.5rem;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.bg-green-500 {
  background-color: #10b981;
}

.bg-red-500 {
  background-color: #ef4444;
}

.bg-gray-500 {
  background-color: #6b7280;
}

.rounded-full {
  border-radius: 100%;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.animate-ping {
  animation: ping 1s infinite;
}

.animate-pulse {
  animation: pulse 2s infinite;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/dot-type.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,MAAA;AACJ;;AAEA;EACI,OAAA;AACJ;;AAEA;EACI,2BAAA;AACJ;;AAEA;EACI,4BAAA;AACJ","sourcesContent":[".pl-2 {\n    padding-left: 0.5rem;\n}\n\n.size-4 {\n    width: 1rem;\n    height: 1rem;\n}\n\n.bg-blue-500 {\n    background-color: #3b82f6;\n}\n\n.bg-green-500 {\n    background-color: #10b981;\n}\n\n.bg-red-500 {\n    background-color: #ef4444;\n}\n\n.bg-gray-500 {\n    background-color: #6b7280;\n}\n\n.rounded-full {\n    border-radius: 100%;\n}\n\n.absolute {\n    position: absolute;\n}\n\n.top-0 {\n    top: 0;\n}\n\n.left-0 {\n    left: 0;\n}\n\n.animate-ping {\n    animation: ping 1s infinite;\n}\n\n.animate-pulse {\n    animation: pulse 2s infinite;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import Auth from '@routes/Auth';
import Root from '@routes/Root';
import Order from '@routes/Order';
import OrderComplete from '@routes/OrderComplete';
import History from '@routes/History';
import HistoryOrder from '@routes/HistoryOrder';
import AdminPage from '@routes/Admin';

import NotFoundPage from '@routes/error/not-found';

import 'rsuite/dist/rsuite.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const domNode = document.getElementById('root');
domNode.setAttribute('notranslate', 'true')
const root = createRoot(domNode);

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
            console.log('SW registered: ', registration);
        }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />
    },
    {
        path: "/login",
        element: <Auth />
    },
    {
        path: "/admin/*",
        element: <AdminPage />
    },
    {
        path: "/history",
        element: <History />
    },
    {
        path: "/history/:orderID",
        element: <HistoryOrder />
    },
    {
        path: "/order/:orderID",
        element: <Order />
    },
    {
        path: "/order/:orderID/complete",
        element: <OrderComplete />
    },
    {
        path: "*",
        element: <NotFoundPage />
    }
])


root.render(
    <>
        <RouterProvider router={router} />
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            theme="light"
            limit={3} />
    </>
);
import React from "react";
import { List, Datagrid, Pagination, SelectField } from "react-admin";

export default function OrderRowsList() {
    return (
        <List pagination={<Pagination rowsPerPageOptions={[1]} />}>
            <Datagrid>
                <SelectField source="one_c_server" label="Сервер 1С" sortable={false} choices={[
                    {id: 'https://1c.nakrepko.info/ut_test/hs/users', name: 'Тест'},
                    {id: 'https://1c.nakrepko.info/UT/hs/users', name: 'Рабочий'}
                ]}/>
            </Datagrid>
        </List>
    )
}
import React, { useEffect, useState } from "react"
import HistoryRow from "@components/History/HistoryRow";
import HeaderUI from "@components/UI/HistoryHeader"
import UserStore from "@stores/user-store";
import FetchDecorator from "@decorators/FetchDecorator";
import { fetchHistory } from "@services/Order";
import { OrderResponse } from "@type/Order";
import { useNavigate } from "react-router-dom";
import { compareEnd } from "@utils/sort";

import '@styles/history.scss'
import ConfirmLogoutModal from "@components/UI/ConfirmLogoutModal";


function History() {
    const { isFetching, encoded_token, logout, fetchData } = UserStore
    const [data, setData] = useState<OrderResponse[]>([])
    const [isSorted, setIsSorted] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const navigate = useNavigate()
    const refreshHandler = () => {
        fetchHistoryData()
    }
    const sortHandler = () => {
        if (!data.length) return
        const sortedArray = data.sort(compareEnd)
        setData(isSorted ? sortedArray : sortedArray.reverse())
        setIsSorted(!isSorted)
    }

    const logoutHandler = async () => {
        await logout()
        await fetchData()
        navigate('/login', { replace: true })
    }

    const fetchHistoryData = async () => {
        const response = await fetchHistory(encoded_token)
        setData(response)
    }
    useEffect(() => {
        if (!isFetching) {
            fetchHistoryData()
        }
    }, [isFetching])
    return (
        <div className='full flex-column'>
            <HeaderUI refreshHandler={refreshHandler} sortHandler={sortHandler} logoutHandler={() => setIsModalOpen(true)} />
            <ConfirmLogoutModal open={isModalOpen} handleClose={() => setIsModalOpen(false)} handleSubmit={logoutHandler} />
            {data.map((elem) => (
                <HistoryRow elem={elem} key={elem.id} onClick={() => navigate(`/history/${elem.id}`)} />
            ))}
        </div>
    )
}

export default FetchDecorator()(History)
import dataProviders from '@admin/DataProvider'
import UserList from '@admin/list/UserList'
import React from 'react'
import { Admin, Layout, Resource, AppBar } from 'react-admin'
import NotFoundPage from './error/not-found'
import OrderList from '@admin/list/OrderLists'
import OrderRowsList from '@admin/list/OrderRowsList'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import SettingsList from '@admin/list/SettingsList'
import { SettingsEdit } from '@admin/edit/SettingsEdit'
import { OrderEdit } from '@admin/edit/OrderEdit'
import { RowEdit } from '@admin/edit/OrderRowEdit'
import '@styles/admin.scss'
import { IconButton } from 'rsuite'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import { UserEdit } from '@admin/edit/UserEdit'
import { UserCreate } from '@admin/create/UserCreate'

const i18nProvider = polyglotI18nProvider(locale => russianMessages, "ru");


const MyAppBar = () => {
    const navigate = useNavigate()
    return (
        <AppBar color="primary" >
            <div className='app-bar'>
                <IconButton circle icon={<Icon icon="bi:house-door" width="24" height="24" style={{ color: 'white' }} />} appearance='ghost' onClick={() => navigate('/')} />
            </div>
        </AppBar>
    )
}
export const MyLayout = ({ children }: { children: React.JSX.Element }) => (
    <Layout appBar={MyAppBar}>
        {children}
    </Layout>
);

function AdminPage() {
    return (
        <Admin
            i18nProvider={i18nProvider}
            basename='/admin'
            catchAll={NotFoundPage}
            dataProvider={dataProviders}
            layout={MyLayout}>
            <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
            <Resource name="orders" list={OrderList} edit={OrderEdit} />
            <Resource name="rows" list={OrderRowsList} edit={RowEdit} />
            <Resource name="settings" list={SettingsList} edit={SettingsEdit} />
        </Admin>
    )
}

export default AdminPage
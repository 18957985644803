import { Icon } from '@iconify/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Header, IconButton } from 'rsuite'

interface HistoryOrderHeaderProps {
    order_number: string,
}

export default function HistoryOrderHeader(props: Readonly<HistoryOrderHeaderProps>) {
    const navigate = useNavigate()
    return (
        <div>
            <Header className='header sticky'>
                <div className='flex-center'>
                    <IconButton icon={<Icon icon="bi:arrow-left" width="32" height="32" style={{ color: 'white' }} />} appearance='subtle' onClick={() => navigate('/history')} />
                </div>
                <p>{props.order_number}</p>
            </Header>
        </div>
    )
}

import React from "react";
import { BooleanInput, Create, SimpleForm, TextInput } from "react-admin";

const validateTextInput = (value: string) => {
    if (!value) {
        return 'Значение не должно быть пустым';
    }
    return undefined;
}

const validateENInput = (value: string) => {
    const regex = /^[a-zA-Z0-9]+$/
    if (!value) {
        return 'Значение не должно быть пустым';
    }
    if (!regex.test(value)) {
        return 'Значение должно быть написано на английском языке и может иметь цифры'
    }
    return undefined
}

export const UserCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="id" label="ID" validate={validateTextInput} />
            <TextInput source="username" label="ФИО" validate={validateTextInput} />
            <TextInput source="login" label="Логин" validate={validateENInput} />
            <TextInput source="password" label="Пароль" validate={validateENInput} />
            <BooleanInput source="is_admin" label="Администратор" />
        </SimpleForm>
    </Create>
);
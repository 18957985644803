import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from 'react-admin';

const apiUrl = `/api/v1/crud`;

interface HttpClientOptions {
    headers?: Headers;
    // Add other properties as needed
}

const httpClient = (url: string, options: HttpClientOptions = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json', 'Access-Control-Expose-Headers': 'X-Total-Count' });
    }
    const token = window.localStorage.getItem('encoded_token') ? window.localStorage.getItem('encoded_token') : window.sessionStorage.getItem('encoded_token');
    options.headers.set(
        'Authorization', 'Basic ' + token
    );
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(apiUrl, httpClient);

export default dataProvider;
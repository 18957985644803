import React from 'react'
import { Icon } from '@iconify/react';

export default function LoaderPage() {
    return (
        <div className='full center'>
            <div className='loader'>
                <Icon icon="svg-spinners:ring-resize" width="128" height="128" />
            </div>
        </div>
    )
}

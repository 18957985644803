import React, { useState } from 'react'
import { Button, Drawer, Input, InputGroup } from 'rsuite'
import { Icon } from '@iconify/react'
import { OrderDataRow } from '@type/Order'
import '@styles/components/dot-type.scss'
import '@styles/components/drawer.scss'

interface OrdersDrawerProps {
    open: boolean,
    setOpen: (arg0: boolean) => void,
    data: OrderDataRow[],
    select: (arg0: number) => void,
}

const dotType = (status: string) => {
    if (status === 'wait') {
        return (
            <div className='pl-2'>
                <div className="relative inline-flex">
                    <div className="size-4 bg-blue-500 rounded-full"></div>
                </div>
            </div>
        )
    }
    if (status === 'complete') {
        return (
            <div className='pl-2'>
                <div className="relative inline-flex">
                    <div className="size-4 bg-green-500 rounded-full"></div>
                </div>
            </div>
        )
    }
    if (status === 'cancel') {
        return (
            <div className='pl-2'>
                <div className="relative inline-flex">
                    <div className="size-4 bg-red-500 rounded-full"></div>
                </div>
            </div>
        )
    }
    return (
        <div className='pl-2'>
            <div className="relative inline-flex">
                <div className="size-4 bg-gray-500 rounded-full"></div>
            </div>
        </div>
    )
}

export default function OrdersDrawer(props: Readonly<OrdersDrawerProps>) {
    const [value, setValue] = useState<string>('')
    return (
        <div>
            <Drawer open={props.open} onClose={() => props.setOpen(false)} size={'full'} closeButton={false}>
                <Drawer.Header closeButton={false} style={{ padding: '1rem', background: '#f7f7fa', border: 0 }}>
                    <InputGroup>
                        <InputGroup.Addon>
                            <Icon icon="bi:search" width="16" height="16" />
                        </InputGroup.Addon>
                        <Input style={{ width: "100%" }} value={value} onChange={(v) => { setValue(v) }} />
                    </InputGroup>
                </Drawer.Header>
                <Drawer.Body style={{ padding: 0, maxHeight: '100%', paddingTop: '1rem', background: '#f7f7fa' }}>
                    {props.data
                        .filter((elem) => elem.product_name.toLowerCase().includes(value.toLowerCase()))
                        .map((elem, index) => (
                            <Button className='order-data-row' onClick={() => { props.select(props.data.indexOf(elem)); props.setOpen(false) }} key={elem.id}>
                                <h4>{index + 1}</h4>
                                <p>
                                    {elem.product_name}
                                </p>
                                <div>
                                    {elem.completed == 0 ? dotType('cancel') : dotType(elem.status)}
                                </div>
                            </Button>
                        ))}
                </Drawer.Body>
            </Drawer>
        </div>
    )
}

import React from "react";
import { List, Datagrid, TextField } from "react-admin";

export default function UserList() {
    return (
        <List>
            <Datagrid>
                <TextField source="id" label="ID" sortable={false} />
                <TextField source="username" label="ФИО" sortable={false} />
                <TextField source="login" label="Логин" sortable={false} />
                <TextField source="password" label="Зашифрованный пароль" sortable={false} />
                <TextField source="is_admin" label="Админ" sortable={false} />
            </Datagrid>
        </List>
    )
}
import React from "react";
import { List, Datagrid, TextField, NumberField, DateField, SelectField, SearchInput } from "react-admin";

const postFilters = [
    <SearchInput source="number" alwaysOn key={'number_search'} />,
]

export default function OrderList() {
    return (
        <List filters={postFilters}>
            <Datagrid>
                <TextField source="id" label="ID заказа в 1С" sortable={false} />
                <SelectField source="status" label="Статус сборки" sortable={false} choices={[
                    { id: 'wait', name: 'Ожидает сборки' },
                    { id: 'complete', name: 'Собран' },
                    { id: 'in_job', name: 'В работе' },
                ]} />
                {/* <ReferenceField source="uid" label="ID пользователя" sortable={false} reference="users" >
                    <TextField source="username"/>
                </ReferenceField> */}
                <TextField source="uid" label="ID пользователя" sortable={false} />
                <DateField source="date" label="Дата" showTime />
                <TextField source="sector" label="Сектор" sortable={false} />
                <TextField source="client" label="Клиент" sortable={false} />
                <TextField source="number" label="Номер" sortable={false} />
                <TextField source="adress" label="Адрес" sortable={false} />
                <TextField source="zone" label="Зона доставки" sortable={false} />
                <DateField source="start_date" label="Начало сборки" sortable={false} showTime />
                <DateField source="end_date" label="Конец сборки" sortable={false} showTime />
                <NumberField source="place_count" label="Количество мест" sortable={false} />
                <NumberField source="row_count" label="Количество строк" sortable={false} />
            </Datagrid>
        </List>
    )
}
import React from 'react';
import { Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

const validateTextInput = (value: string) => {
    if (!value) {
        return 'Значение не должно быть пустым';
    }
    return undefined;
}

export const RowEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="id" label="ID" readOnly />
            <TextInput source="product_name" label="Продукт" validate={validateTextInput} />
            <NumberInput source="ordered" label="Заказано" validate={validateTextInput} />
            <NumberInput source="remain" label="Остаток" validate={validateTextInput} />
            <NumberInput source="completed" label="Собрано" />
            <TextInput source="place" label="Место" validate={validateTextInput} />
            <NumberInput source="row_number" label="Номер строки" validate={validateTextInput} />
            <TextInput source="code" label="Артикул" validate={validateTextInput} />
            <SelectInput source="status" label="Статус сборки" choices={[
                { id: 'wait', name: 'Ожидает сборки' },
                { id: 'complete', name: 'Собран' },
                { id: 'in_job', name: 'В работе' },
            ]} validate={validateTextInput} />
            <ReferenceInput source="order_id" reference="orders">
                <SelectInput validate={validateTextInput} label="ID заказа" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);
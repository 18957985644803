import { Icon } from '@iconify/react'
import React, { useState } from 'react'
import { Button, Input, Modal } from 'rsuite'

import '@styles/components/modal.scss'

interface ConfirmModalProps {
    open: boolean,
    handleClose: (event: React.SyntheticEvent) => void,
    handleSubmit: (arg0: string) => any
}

export default function ConfirmModal(props: Readonly<ConfirmModalProps>) {
    const [value, setValue] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const handleSubmit = async (value: string) => {
        if (!isSubmitted) {
            setIsSubmitted(true)
            const submit = await props.handleSubmit(value)
            if (submit === false)
                setIsSubmitted(false)
        }
    }
    return (
        <div>
            <Modal open={props.open} onClose={props.handleClose} className='modal'>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Количество мест</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input required value={value} onChange={(e) => setValue(e)} type='number' min={0} step={1} />
                </Modal.Body>
                <Modal.Footer className='confirmModalFooter'>
                    <Button onClick={() => { handleSubmit(value) }} appearance="primary" disabled={isSubmitted}>
                        {isSubmitted ? <Icon icon="svg-spinners:blocks-wave" /> : <span>OK</span>}
                    </Button>
                    <Button onClick={props.handleClose} appearance="subtle" disabled={isSubmitted}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

import React from 'react'
import { Icon } from '@iconify/react'

import '@styles/error-page.scss'

function NotFoundPage() {
    return (
        <div className="flex-column error-page">
            <Icon icon="line-md:question-circle" width="128" height="128" />
            <h2 className="">Такой страницы нет</h2>
            <a
                href="/"
                className=""
            >
                <span>Вернуться на начальную страницу</span>
            </a>
        </div>
    )
}

export default NotFoundPage
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { InputGroup, Input, IconButton } from 'rsuite'
import { Icon } from '@iconify/react'
import Table from '@components/Table/Table'
import HeaderUI from '@components/UI/RootHeader'
import FetchDecorator from '@decorators/FetchDecorator'
import UserStore from "@stores/user-store";
import SelectFloorModal from '@components/UI/SelectFloorModal'
import { fetchOrders } from '@services/Order'
import { OrderResponse } from '@type/Order'
import LoaderPage from '@components/UI/LoaderPage'
import { toast } from 'react-toastify'

function Root() {
    const [searchParams, setSearchParams] = useState<string>('')
    const [selectedFloor, setSelectedFloor] = useState<string>()
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [data, setData] = useState<OrderResponse[]>([])
    const [currentDateSort, setCurrentDateSort] = useState<boolean>(false)
    const [oneCFetch, setOneCFetch] = useState<boolean>(false)
    const { isFetching, encoded_token, current_order, fetchData, isAdmin } = UserStore
    const location = useLocation();
    const navigate = useNavigate();

    const handleFloorSelect = (value: string) => {
        window.sessionStorage.setItem('selected_floor', value)
        setSelectedFloor(value)
        setIsModalOpen(false)
    }

    const sortByDate = () => {
        if (data && data.length > 1) {
            setData((prevData) => {
                const sortedData = [...prevData].sort((a, b) => {
                    const dateA = Date.parse(a.date);
                    const dateB = Date.parse(b.date);
                    if (currentDateSort) {
                        return dateA - dateB;
                    } else {
                        return dateB - dateA;
                    }
                });
                return sortedData;
            });
            setCurrentDateSort((prevSort) => !prevSort);
        }
    };

    const fetchOrdersData = async () => {
        setOneCFetch(true)
        const response = await fetchOrders(encoded_token, selectedFloor)
        const sortedResponse = response.sort((a, b) => {
            // First, compare sectors (alphabetically)
            if (a.sector < b.sector) return -1
            if (a.sector > b.sector) return 1

            // If sectors are equal, compare numbers (alphabetically as strings)
            if (a.number < b.number) return -1
            if (a.number > b.number) return 1

            return 0 // If both sector and number are equal
        })
        setData(sortedResponse)
        setOneCFetch(false)
    }

    useEffect(() => {
        if (!isFetching)
            fetchData()
        if (window.sessionStorage.getItem('selected_floor')) {
            setSelectedFloor(window.sessionStorage.getItem('selected_floor'))
            setIsModalOpen(false)
        } else {
            setIsModalOpen(true)
        }
    }, [location.pathname])

    useEffect(() => {
        if (current_order)
            navigate(`/order/${current_order.id}`)
    }, [current_order])

    useEffect(() => {
        setData([])
        if (!isFetching && encoded_token && selectedFloor) {
            fetchOrdersData()
        }
    }, [isFetching, selectedFloor])

    const handleSearch = (e: any) => {
        e.preventDefault()
        setSearchParams(e.target[0].value)
    }

    const handleAdminPage = () => {
        if (isAdmin)
            navigate('/admin')
    }

    return (
        <div className='full flex-column' style={{ position: 'relative', overflowX: 'hidden' }}>
            <SelectFloorModal open={isModalOpen}
                handleSelect={
                    (e) => {
                        if (e) handleFloorSelect(e)
                        else {
                            toast.error(`Не удалось выбрать этаж, автоматически выбран первый, значение этажа было: ${e}`)
                            handleFloorSelect('first_floor')
                        }
                    }
                }
            />
            <div className='sticky-group'>
                <HeaderUI handleSort={sortByDate} handleRefresh={fetchOrdersData} handleUserAdmin={isAdmin === true && handleAdminPage} />
                <form onSubmit={handleSearch}>
                    <InputGroup style={{ borderRadius: 0 }}>
                        <Input placeholder='Элементы для поиска' type='text' name='searchParams' />
                        <InputGroup.Addon>
                            <IconButton icon={
                                <Icon icon="bi:search" width="2rem" height="2rem" />
                            } type='submit' />
                        </InputGroup.Addon>
                    </InputGroup>
                </form>
            </div>
            <div className='table-wrapper'>
                <div className='table-container'>
                    {oneCFetch
                        ? <LoaderPage />
                        : <Table data={(data && !!data.length) ? data.filter((elem) => elem.number.includes(searchParams)) : []} />
                    }
                </div>
            </div>
        </div>
    )
}


export default FetchDecorator()(Root)

import React from "react";
import { EditBase, SaveButton, SelectInput, SimpleForm, Title, Toolbar, useEditController } from "react-admin";
import { useParams } from "react-router-dom";

const validateTextInput = (value: string) => {
    if (!value) {
        return 'Значение не должно быть пустым';
    }
    return undefined;
}

const SettingsEditToolbar = () => (
    <Toolbar>
        <SaveButton />
    </Toolbar>
);

export const SettingsEdit = () => {
    const { id } = useParams();
    const { record, isPending } = useEditController({ resource: 'settings', id })
    if (isPending) return null
    return (
        <EditBase>
            <Title title="База данных 1C" />
            <SimpleForm record={record} toolbar={<SettingsEditToolbar />}>
                <SelectInput source="one_c_server" choices={[
                    { id: "https://1c.nakrepko.info/ut_test/hs/users", name: "Тест" },
                    { id: "https://1c.nakrepko.info/UT/hs/users", name: "Рабочая" },
                ]} validate={validateTextInput}/>
            </SimpleForm>
        </EditBase>
    )
}
import { Icon } from '@iconify/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Header, IconButton } from 'rsuite'

import '@styles/components/header.scss'

interface HeaderUIProps {
    handleSort?: () => void,
    handleRefresh?: () => void,
    handleUserAdmin?: () => void,
}

export default function HeaderUI(props: Readonly<HeaderUIProps>) {
    const navigate = useNavigate()
    return (
        <div>
            <Header className='header sticky'>
                <div>
                    <IconButton icon={<Icon icon="bi:arrow-counterclockwise" width="32" height="32" style={{ color: 'white' }} />} appearance="subtle" onClick={() => props.handleRefresh()} />
                    {props.handleUserAdmin && <IconButton icon={<Icon icon="bi:person-gear" width="32" height="32" style={{ color: 'white' }} />} appearance='subtle' onClick={() => props.handleUserAdmin()} />}
                </div>
                <h3><p>Заказы</p></h3>
                <div>
                    <IconButton icon={<Icon icon="bi:sort-down" width="32" height="32" style={{ color: 'white' }} />} appearance="subtle" onClick={() => props.handleSort()} />
                    <IconButton icon={<Icon icon="bi:clock-history" width="32" height="32" style={{ color: 'white' }} />} appearance="subtle" onClick={() => navigate('/history')} />
                </div>
            </Header>
        </div>
    )
}

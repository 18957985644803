import dayjs from 'dayjs';
import React from 'react';
import { DateTimeInput, Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

const validateTextInput = (value: string) => {
    if (!value) {
        return 'Значение не должно быть пустым';
    }
    return undefined;
}

export const OrderEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="id" label='ID заказа в 1С' readOnly />
            <SelectInput source="status" label="Статус сборки" choices={[
                { id: 'wait', name: 'Ожидает сборки' },
                { id: 'complete', name: 'Собран' },
                { id: 'in_job', name: 'В работе' },
            ]} validate={validateTextInput} />
            <ReferenceInput source="uid" reference='users' label='ID пользователя' >
                <SelectInput label="ID пользователя"/>
            </ReferenceInput>
            <DateTimeInput source="date" label='Дата' validate={validateTextInput} parse={(date: Date) => (date ? dayjs(date) : null)} />
            <TextInput source="sector" label='Сектор' validate={validateTextInput} />
            <TextInput source="client" label='Клиент' validate={validateTextInput} />
            <TextInput source="number" label='Номер' validate={validateTextInput} />
            <TextInput source="adress" label='Адрес' validate={validateTextInput} />
            <TextInput source="zone" label='Зона доставки' validate={validateTextInput} />
            <DateTimeInput source="start_date" label='Начало сборки' parse={(date: Date) => (date ? dayjs(date) : null)} />
            <DateTimeInput source="end_date" label='Конец сборки' parse={(date: Date) => (date ? dayjs(date) : null)} />
            <NumberInput source="place_count" label='Количество мест' />
            <NumberInput source="row_count" label='Количество строк' readOnly />
        </SimpleForm>
    </Edit>
);
import { OrderDataRow, OrderResponse } from "@type/Order";

function compare(a: OrderDataRow, b: OrderDataRow) {
    if (a.place < b.place) {
        return -1;
    }
    if (a.place > b.place) {
        return 1;
    }
    return 0;
}

function compareEnd(a: OrderResponse, b: OrderResponse) {
    const dateA = new Date(a.end_date);
    const dateB = new Date(b.end_date);
    if (dateA < dateB) {
        return -1;
    }
    if (dateA > dateB) {
        return 1;
    }
    return 0;
}

export { compare, compareEnd }
import React from 'react'
import { Button, Modal } from 'rsuite'

import '@styles/components/modal.scss'

interface ConfirmLogoutModalProps {
    open: boolean,
    handleClose: (event: React.SyntheticEvent) => void,
    handleSubmit: () => void
}

export default function ConfirmLogoutModal(props: Readonly<ConfirmLogoutModalProps>) {
    const handleSubmit = async () => {
        props.handleSubmit()
    }
    return (
        <div>
            <Modal open={props.open} onClose={props.handleClose} className='modal'>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Вы точно хотите выйти?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p></p>
                </Modal.Body>
                <Modal.Footer className='confirmModalFooter'>
                    <Button onClick={() => { handleSubmit() }} appearance="primary">
                        <span>Да</span>
                    </Button>
                    <Button onClick={props.handleClose} appearance="subtle">
                        <span>Отмена</span>
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "rsuite"
import { Icon } from "@iconify/react"
import UserStore from "@stores/user-store";
import FetchDecorator from "@decorators/FetchDecorator"
import { fetchOrder, postOrderAssemblyOneC } from "@services/Order"
import { OrderResponse } from "@type/Order"

import '@styles/order-complete.scss'
import LoaderPage from "@components/UI/LoaderPage";
import sleep from "@utils/sleep";

function OrderComplete() {
    const { orderID } = useParams()
    const { isFetching, encoded_token } = UserStore
    const [orderData, setOrderData] = useState<OrderResponse>()
    useEffect(() => {
        !isFetching && fetchData()
        window.sessionStorage.removeItem("currentOrderNum")
    }, [isFetching])
    const navigate = useNavigate()
    const fetchData = async () => {
        const response = await fetchOrder(encoded_token, orderID)
        if (!response) {
            await sleep(1000)
            fetchData()
        } else setOrderData(response)
    }
    const handleOrderAssembly = async () => {
        postOrderAssemblyOneC(orderID, encoded_token)
        navigate('/')
    }
    return orderData ? (
        <div className="flex-column complete-page full">
            <div>
                <Icon icon="line-md:confirm" width="128" height="128" />
                <h3 className="">Распечатай маркировку</h3>
                <h4 className="">{orderData?.number}</h4>
                <h4 className="">{orderData?.client}</h4>
                <h5 className="">{orderData?.adress}</h5>
            </div>
            <Button onClick={() => { handleOrderAssembly() }} appearance="primary"><span>Подтвердить и отправить</span></Button>
        </div>
    ) : (
        <div className="flex-column complete-page full">
            <LoaderPage />
        </div>
    )
}

export default FetchDecorator()(OrderComplete)
import React from 'react';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useNavigate } from "react-router-dom";
import { OrderResponse } from '@type/Order'
import TableRow from './TableRow';

import '@styles/components/table.scss'

interface TableProps {
    data: OrderResponse[]
}

type cellType = string | number | boolean
const columns = [
    {
        accessorKey: 'number',
        header: "Номер",
        cell: (props: { getValue: () => cellType }) => <p>{props.getValue()}</p>
    },
    {
        accessorKey: 'zone',
        header: "Зона доставки",
        cell: (props: { getValue: () => cellType }) => <p>{props.getValue()}</p>
    },
    {
        accessorKey: 'row_count',
        header: "Строк",
        cell: (props: { getValue: () => cellType }) => <p>{props.getValue()}</p>
    },
    {
        accessorKey: 'sector',
        header: "Сектор",
        cell: (props: { getValue: () => cellType }) => <p>{props.getValue()}</p>
    },
]

export default function Table(props: Readonly<TableProps>) {
    const navigate = useNavigate();
    const data = props.data
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel()
    })
    const handleRowClick = (orderID: string) => {
        navigate(`/order/${orderID}`, { replace: true })
    }

    return (
        <table>
            <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id}>
                                {header.column.columnDef.header.toString()}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows
                    .map((row, index_row) => (
                        <TableRow row={row} handleRowClick={handleRowClick} index_row={index_row} key={row.id} />
                    ))}
            </tbody>
        </table>

    )
}
import { OrderResponse } from '@type/Order'
import React from 'react'

interface HistoryRowProps {
  elem: OrderResponse,
  onClick: () => void,
}

export default function HistoryRow(props: Readonly<HistoryRowProps>) {
  const dateParts = props.elem.end_date.split('T')[0].split('-');
  const formattedDate = `${dateParts[2]}.${dateParts[1]}.${dateParts[0].slice(-2)}`;
  return (
    <div className='history-row-container' onClick={() => props.onClick()}>
      <div className='history-info-wrapper'>
        <h4>{props.elem.number}</h4>
        <h5>{props.elem.status == 'complete' ? <span>Собран</span> : <span>В сборке</span>}</h5>
      </div>
      <div>
        <div className='history-object-wrapper'>
          <p>{formattedDate}</p>
          <div className='history-address-wrapper'>
            <p>{props.elem.adress}</p>
            <p>{props.elem.zone}</p>
          </div>
        </div>
        <div className='history-amount-wrapper'>
          <h4>Количество мест: {props.elem.place_count}</h4>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import UserStore from "@stores/user-store"
import LoaderPage from "@components/UI/LoaderPage"
import { useLocation, useNavigate } from "react-router-dom"

const FetchDecorator = () => (Child: React.ComponentType<unknown>) => {
    const DecoratedComponent = observer((props) => {
        const { encoded_token, isFetching, current_order } = UserStore
        const navigate = useNavigate()
        useEffect(() => {
            if (!isFetching && !encoded_token)
                navigate('/login')
        }, [encoded_token])
        useEffect(() => {
            if (!isFetching && current_order)
                if (location.pathname != `/order/${current_order.id}`)
                    navigate(`/order/${current_order.id}`)
        }, [current_order])
        const location = useLocation()
        if (isFetching)
            return <LoaderPage />
        if (encoded_token)
            return <Child {...props} />
    })

    return DecoratedComponent
}

export default FetchDecorator

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import HistoryOrderHeader from '@components/UI/HistoryOrderHeader'
import HistoryOrderRows from '@components/History/HistoryOrderRows';
import UserStore from "@stores/user-store";
import FetchDecorator from '@decorators/FetchDecorator';
import { OrderDataRow, OrderResponse } from '@type/Order';
import { fetchOrder, fetchOrderRows } from '@services/Order';

function HistoryOrder() {
    const { isFetching, encoded_token } = UserStore
    const { orderID } = useParams()
    const [dataOrder, setDataOrder] = useState<OrderResponse>()
    const [dataRows, setDataRows] = useState<OrderDataRow[]>()
    const fetchData = async () => {
        const responseOrder = await fetchOrder(encoded_token, orderID)
        setDataOrder(responseOrder)
        const responseRows = await fetchOrderRows(encoded_token, orderID)
        setDataRows(responseRows)
    }

    useEffect(() => {
        if (!isFetching)
            fetchData()
    }, [isFetching])
    return (
        <div className='full flex-column'>
            <div className='history-order-info-container'>
                <HistoryOrderHeader order_number={dataOrder?.number} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4>Клиент</h4>
                    <h4>
                        <a href={`/order/${dataOrder?.id}/complete`}><span>Маркировка</span></a>
                    </h4>
                </div>
                <h3>{dataOrder?.client}</h3>
                <h4>Дата</h4>
                <h3>{dataOrder?.end_date.split('T')[0]}</h3>
                <h4>Статус</h4>
                <h3>{dataOrder?.status == 'complete' ? <span>Собран</span> : <span>Отменен</span>}</h3>
                <div className='history-order-rows-title'>
                    <h3>Состав заказа <span>({dataRows?.length})</span></h3>
                </div>
                <HistoryOrderRows rows={dataRows} />
            </div>
        </div>
    )
}

export default FetchDecorator()(HistoryOrder)

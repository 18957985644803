import React from 'react'
import { OrderDataRow } from '@type/Order'
import { Icon } from '@iconify/react'

interface HistoryOrderRowsProps {
    rows: OrderDataRow[]
}

export default function HistoryOrderRows(props: Readonly<HistoryOrderRowsProps>) {
    return (
        <div className='history-order-rows-container'>
            {
                props.rows?.map((elem) => (
                    <div key={elem.id} className='order-row'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p>{elem.code}</p>
                            {
                                elem.completed > 0 && <Icon icon="bi:check-circle" style={{ marginTop: '0.1rem' }} />
                            }
                            {
                                elem.completed == 0 && <Icon icon="bi:x-circle" style={{ marginTop: '0.1rem' }} />
                            }
                            {
                                elem.completed == null && <Icon icon="bi:clock" style={{ marginTop: '0.1rem' }} />
                            }
                        </div>
                        <p><strong>{elem.product_name}</strong></p>
                        <div style={{ display: 'flex', width: "100%", justifyContent: 'space-between', alignItems: 'center' }}>
                            <div><span>Собрано: {elem.completed ? <span>{elem.completed}</span> : <span>0</span>} из <span>{elem.ordered}</span></span></div>
                            <div><strong>{elem.completed > 0 ? <span>Собран</span> : elem.completed == 0 ? <span>Отменен</span> : <span>Ожидает</span>}</strong></div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

import React from "react";
import { List, Datagrid, TextField, NumberField, SelectField, SearchInput } from "react-admin";

const postFilters = [
    <SearchInput source="order_id" alwaysOn key={'order_id_search'}/>,
]

export default function OrderRowsList() {
    return (
        <List filters={postFilters}>
            <Datagrid>
                <TextField source="id" label="ID" sortable={false} />
                <TextField source="product_name" label="Продукт" sortable={false} />
                <NumberField source="ordered" label="Заказано" sortable={false} />
                <NumberField source="remain" label="Остаток" sortable={false} />
                <NumberField source="completed" label="Собрано" sortable={false} />
                <TextField source="place" label="Место" sortable={false} />
                <NumberField source="row_number" label="Номер строки" sortable={false} />
                <TextField source="code" label="Артикул" sortable={false} />
                <SelectField source="status" label="Статус" sortable={false} choices={[
                    { id: 'wait', name: 'Ожидает сборки' },
                    { id: 'complete', name: 'Собран' },
                    { id: 'in_job', name: 'В работе' },
                ]} />
                {/* <ReferenceField source="order_id" label="ID заказа" reference="orders" sortable={false} >
                    <TextField source="id"/>
                </ReferenceField> */}
                <TextField source="order_id" label="ID заказа" sortable={false} />
            </Datagrid>
        </List>
    )
}
import handle404ERROR from "@utils/404handler";
import axios from "axios";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";

async function login(encodedToken: string) {
    const result = await axios({
        method: 'post',
        url: '/api/v1/auth/',
        headers: {
            'Authorization': 'Basic ' + encodedToken,
            'X-Requested-With': 'XMLHttpRequest'
        }
    })
        .then((response) => {
            return response
        })
        .catch((error) => {
            console.error(error)
            return error
        })

    return result
}

async function isUserAdmin(encodedToken: string) {
    const result = await axios({
        method: 'get',
        url: '/api/v1/auth/is_current_user_admin',
        headers: {
            'Authorization': 'Basic ' + encodedToken,
            'X-Requested-With': 'XMLHttpRequest'
        }
    })
        .then((response) => {
            if (!handle404ERROR(response.data))
                return response.data as boolean
        })
        .catch((error) => {
            if (error.response.code == StatusCodes.NOT_FOUND)
                toast.error("Ошибка на стороне сервера, пожалуйста, повторите позже")
            console.error(error)
        })

    return result
}

export { login, isUserAdmin }
import { flexRender } from '@tanstack/react-table'
import React, { useState } from 'react'

interface TableRowProps {
    row: any,
    handleRowClick: (arg0: string) => void,
    index_row: number,
}

export default function TableRow(props: Readonly<TableRowProps>) {
    const [startX, setStartX] = useState(0)
    const [currentX, setCurrentX] = useState(0)
    const [isSwiping, setIsSwiping] = useState(false)
    const [colorStep, setColorStep] = useState(0)
    const maxDistance = window.innerWidth * 0.6

    function allowDrop(event: React.DragEvent<HTMLTableRowElement>) {
        event.preventDefault()
    }

    function handleTouchStart(event: React.TouchEvent<HTMLTableRowElement>) {
        setStartX(event.touches[0].clientX)
        setCurrentX(event.touches[0].clientX)
        setIsSwiping(true)
    }

    function handleTouchMove(event: React.TouchEvent<HTMLTableRowElement>) {
        if (!isSwiping) return
        setColorStep((startX - event.touches[0].clientX) / maxDistance)
        if (startX - event.touches[0].clientX > maxDistance * 0.1 && startX - event.touches[0].clientX < maxDistance * 1.1)
            setCurrentX(event.touches[0].clientX)
    }

    function handleTouchEnd() {
        if (!isSwiping) return
        setIsSwiping(false)
        setColorStep(0)
        const distance = currentX - startX
        if (distance < -maxDistance) {
            props.handleRowClick(props.row.original.id)
        } else {
            setCurrentX(startX)
        }
    }

    return (
        <tr
            key={props.row.id}
            draggable
            onDragOver={allowDrop}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onClick={() => props.handleRowClick(props.row.original.id)}
            style={{ transform: `translateX(${currentX - startX}px)`, transition: `${isSwiping ? 'all 0s' : 'all 0.3s'}`, backgroundColor: `${colorStep < 1 && `rgba(8,116,220,${colorStep})`}`, fontSize: '14px' }}
        >
            {props.row.getVisibleCells().map((cell: any, index_cell: number) => (
                <td key={cell.id} id={props.index_row.toString() + index_cell.toString() + cell.column.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    )
}

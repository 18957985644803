import { makeAutoObservable, runInAction } from "mobx"
import { UserCredentials } from "@type/User"
import { isUserAdmin, login } from "@services/User"
import { getCurrentUserOrder } from "@services/Order"
import { OrderResponse } from "@type/Order"

class UserStore {
    current_order: OrderResponse | void
    isAdmin: boolean | void
    encoded_token: string
    isFetching = true

    constructor() {
        this.fetchData()
        makeAutoObservable(this)
    }

    get userData() {
        return this.encoded_token
    }

    fetchData = async () => {
        const encoded_token = window.localStorage.getItem('encoded_token') ? window.localStorage.getItem('encoded_token') : window.sessionStorage.getItem('encoded_token')
        if (!encoded_token) {
            this.isFetching = false
            return
        }
        runInAction(() => this.encoded_token = encoded_token)
        const current_order = await getCurrentUserOrder(encoded_token)
        const isAdmin = await isUserAdmin(encoded_token)
        runInAction(() => this.current_order = current_order)
        runInAction(() => this.isAdmin = isAdmin)
        runInAction(() => this.isFetching = false)
        return current_order
    }

    login = async (credentials: UserCredentials, remember: boolean) => {
        const token = btoa(`${credentials.login}:${credentials.password}`)
        const response = await login(token)
        if (response.status != 200) {
            return false
        }
        remember
            ? window.localStorage.setItem('encoded_token', response.data) // Save to local storage
            : window.sessionStorage.setItem('encoded_token', response.data) // Save to session storage
        return true
    }

    logout = async () => {
        window.localStorage.removeItem('encoded_token')
        window.sessionStorage.removeItem('encoded_token')
        runInAction(() => this.encoded_token = null)
        runInAction(() => this.isAdmin = null)
        runInAction(() => this.current_order = null)
    }
}

export default new UserStore()
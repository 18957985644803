import React, { useState } from 'react'
import { Icon } from '@iconify/react';
import { Button, Checkbox, Input, InputGroup } from 'rsuite';
import UserStore from '@stores/user-store'
import { useNavigate } from 'react-router-dom';
import { UserCredentials } from '@type/User';

import "@/styles/auth.scss"
import { toast } from 'react-toastify';

const initialValues: UserCredentials = {
    login: '',
    password: ''
}

function Auth() {
    const { login, fetchData } = UserStore
    const navigate = useNavigate()
    const [values, setValues] = useState<UserCredentials>(initialValues);
    const [visible, setVisible] = useState(false);
    const [remember, setRemember] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const handleChange = () => {
        setVisible(!visible);
    };

    const handleInputChange = (name: string, value: string) => {
        setValues({
            ...values,
            [name]: value,
        });
    };

    const submitAuth = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsFetching(true)
        const regex = /^[a-zA-Z0-9]+$/;
        if (!regex.test(values.login) || !regex.test(values.password)) {
            toast.error('Значения должны быть только на английском языке и могут содержать цифры');
            setIsFetching(false);
            return;
        }
        const result = await login(values, remember)
        await fetchData()
        if (result)
            navigate('/')
        else
            toast.error('Неправильное имя или пароль')
        setIsFetching(false)
    }

    return (
        <main className='full center'>
            <form className='login-form' onSubmit={submitAuth}>
                <p>Вход</p>
                <label>
                    <span>Логин</span>
                    <InputGroup inside style={{ height: '3rem' }}>
                        <InputGroup.Addon>
                            <Icon icon="bi:person-fill" width="24" height="24" />
                        </InputGroup.Addon>
                        <Input required name='login' style={{ height: "100%" }} onChange={(value) => handleInputChange('login', value.toLowerCase())} />
                    </InputGroup>
                </label>
                <label>
                    <span>Пароль</span>
                    <InputGroup inside style={{ height: '3rem' }}>
                        <InputGroup.Addon>
                            <Icon icon="bi:key-fill" width="24" height="24" />
                        </InputGroup.Addon>
                        <Input required name='password' type={visible ? 'text' : 'password'} style={{ height: "100%" }} onChange={(value) => handleInputChange('password', value)} />
                        <InputGroup.Button onClick={handleChange} style={{ height: "100%" }}>
                            {visible ? <Icon icon="bi:eye-fill" width="24" height="24" /> : <Icon icon="bi:eye-slash-fill" width="24" height="24" />}
                        </InputGroup.Button>
                    </InputGroup>
                </label>
                <Checkbox checked={remember} onChange={(_, checked) => setRemember(checked)}><span>Запомнить меня</span></Checkbox>
                <Button appearance="primary" disabled={isFetching} type='submit' style={{ height: '3rem' }}>
                    {isFetching ? <Icon icon="svg-spinners:ring-resize" width="32" height="32" /> : <span>Войти</span>}
                </Button>
            </form>
        </main>
    )
}

export default Auth
import { Icon } from '@iconify/react'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Header, IconButton } from 'rsuite'

interface HistoryHeaderProps {
    sortHandler: () => void,
    refreshHandler: () => void,
    logoutHandler: () => void,
}

export default function HistoryHeader(props: Readonly<HistoryHeaderProps>) {
    const navigate = useNavigate();
    return (
        <div>
            <Header className='header sticky'>
                <div className='flex-center'>
                    <IconButton icon={<Icon icon="bi:house-door" width="32" height="32" style={{ color: 'white' }} />} appearance='subtle' onClick={() => navigate('/')} />
                    <p>История</p>
                </div>
                <div>
                    <IconButton icon={<Icon icon="bi:box-arrow-left" width="32" height="32" style={{ color: 'white' }} />} appearance="subtle" onClick={() => { props.logoutHandler() }} />
                    <IconButton icon={<Icon icon="bi:sort-down" width="32" height="32" style={{ color: 'white' }} />} appearance="subtle" onClick={() => { props.sortHandler() }} />
                    <IconButton icon={<Icon icon="bi:arrow-counterclockwise" width="32" height="32" style={{ color: 'white' }} />} appearance="subtle" onClick={() => { props.refreshHandler() }} />
                </div>
            </Header>
        </div>
    )
}

import React from 'react'
import { Button, Modal } from 'rsuite'

import '@styles/components/modal.scss'

interface SelectFloorModalProps {
    open: boolean,
    handleSelect: (e: string) => void,
}

export default function SelectFloorModal(props: Readonly<SelectFloorModalProps>) {
    return (
        <div>
            <Modal open={props.open} className='modal select-modal'>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Выберите этаж</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button name='first_floor' onClick={() => { props.handleSelect('first_floor') }} appearance="primary">
                        <span>1 Этаж</span>
                    </Button>
                    <Button name='second_floor' onClick={() => { props.handleSelect('second_floor') }} appearance="primary">
                        <span>2 Этаж</span>
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    )
}
